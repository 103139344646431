@charset "UTF-8";

html {
  text-size-adjust: auto;
}

body {
	font-family: 'Noto Sans JP', sans-serif;
  padding: 0 0 0 130px;
  @include laptop {
    padding-left: 15px;
  }
  @include sp {
    padding-left: 0;
  }
  &.user {
    padding-left: 0;
  }
}

// selectのデフォルトクリア
select::-ms-expand {
  display: none;
}
// inputのデフォルトクリア＆iOS Safariでinputフォーカス時に拡大されてしまうのを防ぐ
input::-ms-clear {
  visibility:hidden;
  @include fz(16);
}

.wrap {
  @include sp {
    position: relative;
    overflow: hidden;
    padding-bottom:50px;
  }
}
.top .wrap {
  padding-bottom:0;
}

.sp {
  display: none;
}
@media (max-width: 425px) {
  .pc {
    display: none;
	}
  .sp {
    display: inherit;
	}
}
