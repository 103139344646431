/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  text-size-adjust: auto;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  padding: 0 0 0 130px;
}

@media (max-width: 1279px) {
  body {
    padding-left: 15px;
  }
}

@media (max-width: 425px) {
  body {
    padding-left: 0;
  }
}

body.user {
  padding-left: 0;
}

select::-ms-expand {
  display: none;
}

input::-ms-clear {
  visibility: hidden;
  font-size: 16px;
  font-size: 1rem;
}

@media (max-width: 425px) {
  .wrap {
    position: relative;
    overflow: hidden;
    padding-bottom: 50px;
  }
}

.top .wrap {
  padding-bottom: 0;
}

.sp {
  display: none;
}

@media (max-width: 425px) {
  .pc {
    display: none;
  }
  .sp {
    display: inherit;
  }
}
