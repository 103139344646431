@charset "UTF-8";

@import "mixin";
@import "settings/reset";
@import "settings/base";

// font
//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
// Bold対応↓
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&display=swap');
